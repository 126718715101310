export const useNewsDataManager = () => {
    const route = useRoute();
    const newsStore = useNews();
    const newsDataService = useNewsDataService();

    /* Resources */
    const getNewsAnnouncementReportTypes = async () => {
        const storedNewsAnnouncementReportTypes = computed(() => {
            return newsStore.news_announcement_report_types;
        })

        if (storedNewsAnnouncementReportTypes.value == null || storedNewsAnnouncementReportTypes.value.length == 0 || !newsStore.isNewsAnnouncementReportTypesValid()) {
            const newsAnnouncementReportTypes = await newsDataService.fetchNewsAnnouncementReportTypes();
            if (newsAnnouncementReportTypes)
                newsStore.setNewsAnnouncementReportTypes(newsAnnouncementReportTypes);
        }

        return storedNewsAnnouncementReportTypes;
    }

    const getNewsAnnouncementQuota = async () => {
        const storedNewsAnnouncementQuota = computed(() => {
            return newsStore.news_announcement_quota;
        })
        if (storedNewsAnnouncementQuota.value == null || !newsStore.isNewsAnnouncementQuotaValid()) {
            const newsAnnouncementQuota = await newsDataService.fetchNewsAnnouncementQuota();
            if (newsAnnouncementQuota)
                newsStore.setNewsAnnouncementQuota(newsAnnouncementQuota);
        }

        return storedNewsAnnouncementQuota.value;
    }

    const getNewsAnnouncementCategories = async () => {
        const storedNewsAnnouncementCategories = computed(() => {
            return newsStore.news_announcement_categories;
        })

        if (storedNewsAnnouncementCategories.value == null || storedNewsAnnouncementCategories.value.length == 0 || !newsStore.isNewsAnnouncementCategoriesValid()) {
            const newsAnnouncementCategories = await newsDataService.fetchNewsAnnouncementCategories();
            if (newsAnnouncementCategories)
                newsStore.setNewsAnnouncementCategories(newsAnnouncementCategories);
        }

        return storedNewsAnnouncementCategories;
    }

    const getNewsAnnouncementTags = async () => {
        const storedNewsAnnouncementTags = computed(() => {
            return newsStore.news_announcement_tags;
        })

        if (storedNewsAnnouncementTags.value == null || storedNewsAnnouncementTags.value.length == 0 || !newsStore.isNewsAnnouncementTagsValid()) {
            const newsAnnouncementTags = await newsDataService.fetchNewsAnnouncementTags();
            if (newsAnnouncementTags)
                newsStore.setNewsAnnouncementTags(newsAnnouncementTags);
        }

        return storedNewsAnnouncementTags;
    }

    const getNewsPostCategories = async () => {
        const storedNewsPostCategories = computed(() => {
            return newsStore.news_post_categories;
        })

        if (storedNewsPostCategories.value == null || storedNewsPostCategories.value.length == 0 || !newsStore.isNewsPostCategoriesValid()) {
            const newsPostCategories = await newsDataService.fetchNewsPostCategories();
            if (newsPostCategories)
                newsStore.setNewsPostCategories(newsPostCategories);
        }

        return storedNewsPostCategories;
    }

    const getNewsAnnouncementFeaturedPackages = async () => {
        const storedNewsAnnouncementFeaturedPackages = computed(() => {
            return newsStore.news_announcement_featured_packages;
        })

        if (storedNewsAnnouncementFeaturedPackages.value == null || storedNewsAnnouncementFeaturedPackages.value.length == 0 || !newsStore.isNewsAnnouncementFeaturedPackagesValid()) {
            const newsAnnouncementFeaturedPackages = await newsDataService.fetchNewsAnnouncementFeaturedPackages();
            if (newsAnnouncementFeaturedPackages)
                newsStore.setNewsAnnouncementFeaturedPackages(newsAnnouncementFeaturedPackages);
        }

        return storedNewsAnnouncementFeaturedPackages;
    }

    /*Resources End*/

    /* Dashboard News Announcement */

    /* News Announcement Dashboard Data */

    const getDashboardNewsAnnouncementsByFilter = async () => {
        const dashboardData = await newsDataService.fetchNewsAnnouncementsDataForDashboardByFilter();
        return computed(() => dashboardData?.value)
    }

    const getDashboardNewsAnnouncementsFromUrl = async (url: string) => {
        const results = await newsDataService.fetchNewsAnnouncementsDataForDashboardFromUrl(url);
        useScrollManager().scrollToTop();
        return computed(() => results)
    }

    /* News Announcement Data End */

    /* Dashboard News Announcement */
    const getCurrentDashboardNewsAnnouncement = async () => {
        const storedNewsAnnouncement = computed(() => {
            return newsStore.current_dashboard_news_announcement;
        })

        const slug = route.params.slug;
        if ((storedNewsAnnouncement.value == null || storedNewsAnnouncement.value.id == null || storedNewsAnnouncement.value.slug != route.params.slug || !newsStore.isCurrentDashboardNewsAnnouncementValid()) && slug) {
            const newsAnnouncement = await newsDataService.fetchNewsAnnouncementResourceForDashboard(slug as string);
            if (newsAnnouncement)
                newsStore.setCurrentDashboardNewsAnnouncement(newsAnnouncement);
        }

        return storedNewsAnnouncement;
    }

    const createNewNewsAnnouncement = async (data: object) => {
        const createdNewsAnnouncement = await newsDataService.requestToCreateNewNewsAnnouncement(data);
        if (createdNewsAnnouncement) {
            newsStore.setCurrentDashboardNewsAnnouncement(createdNewsAnnouncement);
            useAlertSetter().setSuccessAlert("News Announcement Created Successfully");
            navigateTo({
                name: "dashboard-news",
                query: {
                    filter: "active",
                }
            });
        }
    };

    const updateNewsAnnouncement = async (data: object) => {
        const updatedNewsAnnouncement = await newsDataService.requestToUpdateNewsAnnouncement(data);
        if (updatedNewsAnnouncement) {
            newsStore.setCurrentDashboardNewsAnnouncement(updatedNewsAnnouncement);
            useAlertSetter().setSuccessAlert("News Announcement Updated Successfully");
            navigateTo({
                name: "dashboard-news-slug",
                params: {slug: newsStore.current_dashboard_news_announcement?.slug as string},
            })
        }
    };

    const deactivateCurrentDashboardNewsAnnouncement = async () => {
        const deactivatedNewsAnnouncement = await newsDataService.requestToDeactivateCurrentDashboardNewsAnnouncement();
        if (deactivatedNewsAnnouncement) {
            newsStore.setCurrentDashboardNewsAnnouncement(deactivatedNewsAnnouncement)
            navigateTo({
                name: "dashboard-news-slug",
                params: {
                    slug: deactivatedNewsAnnouncement.slug
                }
            });
        }
    };

    const submitCurrentDashboardNewsAnnouncementForApproval = async () => {
        const submittedNewsAnnouncement = await newsDataService.requestToSubmitCurrentDashboardNewsAnnouncementForApproval();
        if (submittedNewsAnnouncement) {
            newsStore.setCurrentDashboardNewsAnnouncement(submittedNewsAnnouncement);
            navigateTo({
                name: "dashboard-news-slug",
                params: {
                    slug: submittedNewsAnnouncement.slug,
                },
            });
        }
    };
    /* Dashboard News Announcement End*/

    /*New Announcement Images*/
    const getCurrentDashboardNewsAnnouncementImages = async () => {
        const storedNewsAnnouncementImages = computed(() => {
            return newsStore.current_dashboard_news_announcement_images;
        })
        if (storedNewsAnnouncementImages.value == null || storedNewsAnnouncementImages.value.length == 0 || !newsStore.isCurrentDashboardNewsAnnouncementImagesValid()) {
            const images = await newsDataService.fetchCurrentDashboardNewsAnnouncementImages();
            if (images)
                newsStore.setCurrentDashboardNewsAnnouncementImages(images);
        }
        return storedNewsAnnouncementImages;
    }

    const addImageToCurrentDashboardNewsAnnouncementImages = (imageUrl: string) => {
        const storedNewsAnnouncementImages = computed(() => {
            return newsStore.current_dashboard_news_announcement_images;
        })
        newsStore.addImageToCurrentDashboardNewsAnnouncementImages(imageUrl);
        useAlertSetter().setSuccessAlert("Successfully added image for news announcement");
        return storedNewsAnnouncementImages;
    }

    const removeImageFromCurrentDashboardNewsAnnouncementImagesAtIndex = async (index: number) => {
        const storedNewsAnnouncementImages = computed(() => {
            return newsStore.current_dashboard_news_announcement_images;
        })
        const results = await newsDataService.requestToDeleteCurrentDashboardNewsAnnouncementImageByIndex(index);
        if (results)
            newsStore.removeImageFromCurrentDashboardNewsAnnouncementImagesAtIndex(index);

        useAlertSetter().setSuccessAlert("Successfully removed image from news announcement");
        return storedNewsAnnouncementImages;
    }
    /*Dashboard News Announcement Images End*/

    /* Dashboard Pinned News Announcement*/
    const pinNewsAnnouncement = async () => {
        const pinnedNewsAnnouncement = await newsDataService.requestToPinANewsAnnouncementBySlug();
        if (pinnedNewsAnnouncement) {

        }
    };

    const unPinNewsAnnouncement = async () => {
        const unPinnedNewsAnnouncement = await newsDataService.requestToUnPinANewsAnnouncementBySlug();
        if (unPinnedNewsAnnouncement) {

        }
    };

    const getPinnedDashboardNewsAnnouncementsData = async () => {
        const pinnedNewsAnnouncementData = await newsDataService.fetchPinnedNewsAnnouncementsData();
        return computed(() => pinnedNewsAnnouncementData?.value)
    }

    const getPinnedDashboardNewsAnnouncementsDataFromUrl = async (url: string) => {
        const results = await newsDataService.fetchPinnedNewsAnnouncementsDataFromUrl(url);
        useScrollManager().scrollToTop();
        return computed(() => results)
    }

    /* Dashboard Pinned News Announcement Ends*/

    /* News Announcement Orders */
    const getCurrentDashboardNewsAnnouncementOrders = async () => {
        const storedNewsAnnouncementOrders = computed(() => {
            return newsStore.current_dashboard_news_announcement_orders;
        })
        if (storedNewsAnnouncementOrders.value == null || storedNewsAnnouncementOrders.value.length == 0 || !newsStore.isCurrentDashboardNewsAnnouncementOrdersValid()) {
            const newsAnnouncementOrders = await newsDataService.fetchCurrentDashboardNewsAnnouncementFeaturedPackageOrders();
            if (newsAnnouncementOrders)
                newsStore.setCurrentDashboardNewsAnnouncementOrders(newsAnnouncementOrders);
        }
        return storedNewsAnnouncementOrders;
    }

    const createNewNewsAnnouncementOrder = async (formData: object) => {
        const createdNewsAnnouncementOrder = await newsDataService.submitFormToCreateNewNewsAnnouncementFeaturedPackageOrder(formData);
        if (createdNewsAnnouncementOrder) {
            newsStore.addOrderToCurrentDashboardNewsAnnouncementOrders(createdNewsAnnouncementOrder)
            useAlertSetter().setSuccessAlert("Successfully Purchased Order!")
            newsStore.updateFeaturedUntilDateOfCurrentDashboardNewsAnnouncement(createdNewsAnnouncementOrder.order_for_featured_until, createdNewsAnnouncementOrder.order_for_featured_until_formatted);
        }
        return createdNewsAnnouncementOrder;
    };

    const refundNewsAnnouncementOrder = async (orderId: number) => {
        const refundedNewsAnnouncementOrder = await newsDataService.requestToRefundNewsAnnouncementFeaturedPackageOrderByOrderId(orderId);
        if (refundedNewsAnnouncementOrder) {
            useAlertSetter().setSuccessAlert("Successfully Refunded Order! Your refunded amount will appear in your account within 3-5 business days.");
            newsStore.updateFeaturedUntilDateOfCurrentDashboardNewsAnnouncement(refundedNewsAnnouncementOrder.order_for_featured_until, refundedNewsAnnouncementOrder.order_for_featured_until_formatted);
            newsStore.updateOrderOfCurrentDashboardNewsAnnouncement(refundedNewsAnnouncementOrder);
        }
    };

    /*Dashboard News Announcements Orders End*/

    /*News Main*/

    /* Main News Announcement*/

    const getNewsAnnouncement = async () => {
        const newsAnnouncement = await newsDataService.fetchNewsAnnouncementBySlug();
        return computed(() => newsAnnouncement);
    }

    const reportNewsAnnouncement = async (reportData: object) => {
        const newsAnnouncement = await newsDataService.requestToCreateNewsAnnouncementReport(reportData);
        return computed(() => newsAnnouncement);
    }

    /* Main News Announcement End*/

    /* Main News Post*/
    const getNewsPostsForHome = async (category: string | null) => {
        const homeNewsPostsData = await newsDataService.fetchNewsPostsForHome(category);
        return computed(() => homeNewsPostsData?.value)
    }

    const getNewsPostsForHomeFromUrl = async (url: string) => {
        const results = await newsDataService.fetchNewsPostsForHomeFromUrl(url);
        useScrollManager().scrollToTop();
        return computed(() => results)
    }

    const getNewsPost = async () => {
        const newsPost = await newsDataService.fetchNewsPostBySlug();
        return computed(() => newsPost);
    }

    const getNewsPostCategory = async () => {
        const newsPostCategory = await newsDataService.fetchNewsPostCategoryBySlug();
        return computed(() => newsPostCategory);
    }

    /* Main News Post End*/

    /* News Search */
    const searchNewsAnnouncementsByCriteria = async (query: string) => {
        const searchedNewsAnnouncements = await newsDataService.requestToSearchNewsAnnouncementsByCriteria(query);
    }


    return {
        getNewsAnnouncementReportTypes,
        getNewsAnnouncementQuota,
        getNewsAnnouncementCategories,
        getNewsAnnouncementTags,
        getNewsPostCategories,
        getNewsAnnouncementFeaturedPackages,

        getDashboardNewsAnnouncementsByFilter,
        getDashboardNewsAnnouncementsFromUrl,
        submitCurrentDashboardNewsAnnouncementForApproval,
        createNewNewsAnnouncement,
        getCurrentDashboardNewsAnnouncement,
        updateNewsAnnouncement,
        deactivateCurrentDashboardNewsAnnouncement,

        getCurrentDashboardNewsAnnouncementImages,
        addImageToCurrentDashboardNewsAnnouncementImages,
        removeImageFromCurrentDashboardNewsAnnouncementImagesAtIndex,

        pinNewsAnnouncement,
        unPinNewsAnnouncement,
        getPinnedDashboardNewsAnnouncementsData,
        getPinnedDashboardNewsAnnouncementsDataFromUrl,

        getCurrentDashboardNewsAnnouncementOrders,
        createNewNewsAnnouncementOrder,
        refundNewsAnnouncementOrder,

        getNewsAnnouncement,
        reportNewsAnnouncement,

        getNewsPostsForHome,
        getNewsPostsForHomeFromUrl,
        getNewsPost,
        getNewsPostCategory,

    }
}