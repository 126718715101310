import type {
    DashboardNewsAnnouncement,
    DashboardNewsAnnouncementData,
    DashboardNewsAnnouncementFeaturedPackageOrder,
    DashboardPinnedNewsAnnouncement, DashboardPinnedNewsAnnouncementData,
    NewsAnnouncement,
    NewsAnnouncementCategory,
    NewsAnnouncementFeaturedPackage,
    NewsAnnouncementQuota,
    NewsAnnouncementReportType,
    NewsAnnouncementTag,
    NewsPost,
    NewsPostCategory,
    NewsPostHome,
    NewsTicker
} from "~/types";

export const useNewsDataService = () => {
    const nuxtApp = useNuxtApp();
    const route = useRoute();

    /* Resources */
    const fetchNewsAnnouncementReportTypes = async () => {
        const { data, error } = await useAsyncData('news-announcement-report-types',
            () => nuxtApp.$getNewsAnnouncementReportTypes()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as NewsAnnouncementReportType[];
        }
    }
    
    const fetchNewsAnnouncementQuota = async () => {
        const { data, error } = await useAsyncData('news-announcement-quota',
            () => nuxtApp.$getNewsAnnouncementQuota()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as NewsAnnouncementQuota;
        }
    }

    const fetchNewsAnnouncementCategories = async () => {
        const { data, error } = await useAsyncData('news-announcement-categories',
            () => nuxtApp.$getNewsAnnouncementCategories()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as NewsAnnouncementCategory[];
        }
    }

    const fetchNewsAnnouncementTags = async () => {
        const { data, error } = await useAsyncData('news-announcement-tags',
            () => nuxtApp.$getNewsAnnouncementTags()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as NewsAnnouncementTag[];
        }
    }

    const fetchNewsPostCategories = async () => {
        const { data, error } = await useAsyncData('news-post-categories',
            () => nuxtApp.$getNewsPostCategories()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as NewsPostCategory[];
        }
    }

    const fetchNewsAnnouncementFeaturedPackages = async () => {
        const { data, error } = await useAsyncData('news-announcement-featured-packages',
            () => nuxtApp.$getNewsAnnouncementFeaturedPackages()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as NewsAnnouncementFeaturedPackage[];
        }
    }

    /*Resources End*/

    /* News Dashboard Data */
    const fetchNewsAnnouncementsDataForDashboardByFilter = async () => {
        const { data, error, refresh } = await useAsyncData(`${route.query.filter ?? 'active'}-news-announcements`,
            () => useNuxtApp().$getMyNewsAnnouncementsByFilter((route.query.filter as string) ?? 'active')
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            const filteredNewsAnnouncementsData = computed<DashboardNewsAnnouncementData>(() => {
                return {
                    news_announcements: data.value.data.data,
                    pagination_meta_data: data.value.data.meta,
                    pagination_links: data.value.data.links,
                    statistics: data.value.statistics,
                }
            });

            return ref({
                filteredNewsAnnouncementsData,
                refresh
            });
        }
    }

    const fetchNewsAnnouncementsDataForDashboardFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getNewsAnnouncementsDataFromUrl(url);
            const newsAnnouncementsData = {
                news_announcements: response.data.data,
                pagination_meta_data: response.data.meta,
                pagination_links: response.data.links,
                statistics: response.statistics,
            } as DashboardNewsAnnouncementData;

            return newsAnnouncementsData;

        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    };
    /* News Announcement Dashboard Data End */

    /* Dashboard News Announcement */
    const fetchNewsAnnouncementResourceForDashboard = async (slug: string) => {
        const { data, error } = await useAsyncData('dashboard-news-announcement.vue',
            () => nuxtApp.$getMyNewsAnnouncementBySlug(slug)
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value?.data as DashboardNewsAnnouncement
        }
    };

    const requestToCreateNewNewsAnnouncement = async (data: object) => {
        try {
            const response = await nuxtApp.$createMyNewsAnnouncement(data);
            return response.data as DashboardNewsAnnouncement;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateNewsAnnouncement = async (data: object) => {
        try {
            const response = await nuxtApp.$updateNewsAnnouncementBySlug(route.params.slug as string, data);
            return response.data as DashboardNewsAnnouncement;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToDeactivateCurrentDashboardNewsAnnouncement = async () => {
        try {
            const response = await nuxtApp.$deactivateMyNewsAnnouncementBySlug(route.params.slug as string);
            return response.data as DashboardNewsAnnouncement;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToSubmitCurrentDashboardNewsAnnouncementForApproval = async () => {
        try {
            const response = await nuxtApp.$submitMyNewsAnnouncementForApprovalBySlug(route.params.slug as string);
            return response.data as DashboardNewsAnnouncement;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    /* Dashboard News Announcement End*/

    /*New Announcement Images*/
    const fetchCurrentDashboardNewsAnnouncementImages = async () => {
        const { data, error } = await useAsyncData('dashboard-news-announcement.vue-images',
            () => nuxtApp.$getMyNewsAnnouncementImagesByNewsAnnouncementSlug(route.params.slug as string)
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value?.data as string[];
        }
    };

    const requestToDeleteCurrentDashboardNewsAnnouncementImageByIndex = async (index: number) => {
        try {
            const response = await nuxtApp.$deleteMyNewsAnnouncementImageByNewsAnnouncementImageIndex(route.params.slug as string, index);
            return response.data.message as string;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

        /*New Announcement Images Ends*/

     /* Dashboard Pinned News Announcement*/
     const requestToPinANewsAnnouncementBySlug = async () => {
        try {
            const response = await nuxtApp.$pinNewsAnnouncementBySlug(route.params.slug as string);
            return response.data as DashboardPinnedNewsAnnouncement;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUnPinANewsAnnouncementBySlug = async () => {
        try {
            const response = await nuxtApp.$unPinNewsAnnouncementBySlug(route.params.slug as string);
            return response.data as NewsAnnouncement;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const fetchPinnedNewsAnnouncementsData = async () => {
        const { data, error, refresh } = await useAsyncData(`dashboard-pinned-news-announcements`,
            () => useNuxtApp().$getMyPinnedNewsAnnouncements()
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            const pinnedNewsAnnouncementsData = computed<DashboardPinnedNewsAnnouncementData>(() => {
                return {
                    news_announcements: data.value.data.data,
                    pagination_meta_data: data.value.data.meta,
                    pagination_links: data.value.data.links,
                }
            });
            return ref({ 
                pinnedNewsAnnouncementsData,
                refresh 
            });
        }
    };

    const fetchPinnedNewsAnnouncementsDataFromUrl = async(url:string)=>{
        try {
            const response = await useNuxtApp().$getMyPinnedNewsAnnouncementsDataFromUrl(url);
            const pinnedNewsAnnouncementsData = {
                news_announcements: response.data.data,
                pagination_meta_data: response.data.meta,
                pagination_links: response.data.links,
            } as DashboardPinnedNewsAnnouncementData;

            return pinnedNewsAnnouncementsData;

        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    
    }

    /* Dashboard Pinned News Announcement Ends*/

    /* News Announcement Orders */

    const fetchCurrentDashboardNewsAnnouncementFeaturedPackageOrders = async () => {
        const { data, error } = await useAsyncData('dashboard-news-announcement.vue-orders',
            () => nuxtApp.$getMyNewsAnnouncementOrdersByNewsAnnouncementSlug(route.params.slug as string)
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value?.data as DashboardNewsAnnouncementFeaturedPackageOrder[];
        }
    }; 

    const submitFormToCreateNewNewsAnnouncementFeaturedPackageOrder = async(newFeaturedPackageOrderForm:object)=>{
        try {
            const response = await nuxtApp.$createOrderForMyNewsAnnouncementBySlug(route.params.slug as string, newFeaturedPackageOrderForm)
            return response.data as DashboardNewsAnnouncementFeaturedPackageOrder;
        } catch (error: any) {
            setError(error.data as ApiError)
            return null;
        }
    }

    const requestToRefundNewsAnnouncementFeaturedPackageOrderByOrderId = async(orderId:number)=>{
        try {
            const response = await nuxtApp.$refundOrderForMyNewsAnnouncement(route.params.slug as string, orderId);
            return response.data as DashboardNewsAnnouncementFeaturedPackageOrder;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }
    /*Dashboard News Announcements Orders End*/

    /* Main News Announcement*/
    


    const fetchNewsAnnouncementBySlug = async () => {
        const { data, error } = await useAsyncData('news-announcement',
            () => nuxtApp.$getNewsAnnouncementBySlug(route.params.slug as string)
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as NewsAnnouncement;
        }
    }

    const requestToCreateNewsAnnouncementReport = async (reportData:object) => {
        try{
            const results = () => nuxtApp.$createNewsAnnouncementReport(reportData)
            return results;
        }catch(error:any){
            setError(error.value.data as ApiError);
            return null;
        }
    }

    /* Main News Announcement End*/

    /* Main News Post*/
    const fetchNewsPostsForHome = async (category:string|null) => {
        const { data, error, refresh } = await useAsyncData(`news-posts-home-data`,
            () => useNuxtApp().$getNewsPosts(category)
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            const filteredNewsAnnouncementsData = computed<NewsPostHome>(() => {
                return {
                    news_posts: data.value.data.data,
                    pagination_meta_data: data.value.data.meta,
                    pagination_links: data.value.data.links,
                }
            });

            return ref({ 
                filteredNewsAnnouncementsData,
                refresh 
            });
        } 
    }

    const fetchNewsPostsForHomeFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getNewsPostsFromUrl(url);
            const newsPostData = {
                news_posts: response.data.data,
                pagination_meta_data: response.data.meta,
                pagination_links: response.data.links,
            } as NewsPostHome;

            return newsPostData;

        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    }

    const fetchNewsPostBySlug = async () => {
        const { data, error } = await useAsyncData('news-post',
            () => nuxtApp.$getNewsPostBySlug(route.params.slug as string)
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as NewsPost;
        }
    }

    const fetchNewsPostCategoryBySlug = async () => {
        const { data, error } = await useAsyncData('news-post-category',
            () => nuxtApp.$getNewsPostBySlug(route.params.slug as string)
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as NewsPostCategory;
        }
    }

    /* Main News Post End*/

     /* News Search */

    const requestToSearchNewsAnnouncementsByCriteria = async (query:string) => {
        // const { data, error } = await useAsyncData('searched-news-announcements',
        //     () => nuxtApp.$searchNewsAnnouncementsByCriteria(query)
        // );

        // if (error.value != null) {
        //     setError(error.value.data as ApiError);
        //     return null;
        // } else {
        //     return data.value.data as NewsPostCategory;
        // }
    }

    function setError(error: ApiError) {
        useAlertSetter().setErrorAlert(error);
    }

   return {
        fetchNewsAnnouncementReportTypes,
        fetchNewsAnnouncementQuota,
        fetchNewsAnnouncementCategories,
        fetchNewsAnnouncementTags,
        fetchNewsPostCategories,
        fetchNewsAnnouncementFeaturedPackages,

        fetchNewsAnnouncementsDataForDashboardByFilter,
        fetchNewsAnnouncementsDataForDashboardFromUrl,
        requestToSubmitCurrentDashboardNewsAnnouncementForApproval,
        requestToCreateNewNewsAnnouncement,
        fetchNewsAnnouncementResourceForDashboard,
        requestToUpdateNewsAnnouncement,
        requestToDeactivateCurrentDashboardNewsAnnouncement,

        fetchCurrentDashboardNewsAnnouncementImages,
        requestToDeleteCurrentDashboardNewsAnnouncementImageByIndex,

        requestToPinANewsAnnouncementBySlug,
        requestToUnPinANewsAnnouncementBySlug,
        fetchPinnedNewsAnnouncementsData,
        fetchPinnedNewsAnnouncementsDataFromUrl,

        fetchCurrentDashboardNewsAnnouncementFeaturedPackageOrders,
        submitFormToCreateNewNewsAnnouncementFeaturedPackageOrder,
        requestToRefundNewsAnnouncementFeaturedPackageOrderByOrderId,

        fetchNewsAnnouncementBySlug,
        requestToCreateNewsAnnouncementReport,

        fetchNewsPostsForHome,
        fetchNewsPostsForHomeFromUrl,
        fetchNewsPostBySlug,
        fetchNewsPostCategoryBySlug,

        requestToSearchNewsAnnouncementsByCriteria,

    }


}
